import React from 'react';
import { Link } from 'gatsby'
import Slider from "react-slick";
import { ChevronLeft, ChevronRight } from 'react-feather';

import ArticleItem from './articleItem';

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
  <span {...props}>{children}</span>
);

class Carousel extends React.Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      nextArrow: <SlickButtonFix><ChevronRight color="black" size={60} /></SlickButtonFix>,
      prevArrow: <SlickButtonFix><ChevronLeft color="black" size={60} /></SlickButtonFix>,
      responsive: [
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className="carousel-wrapper">
        <h3 className='carousel-header'>
          <Link to={`/${this.props.link}`}>{this.props.title}</Link>
        </h3>
        <Slider {...settings} className="carousel">
          {
            this.props.items.map((item) => {
              return (
                <div className="slide" key={item.node.id}>
                  <ArticleItem
                    key={item.node.id}
                    slug={item.node.slug}
                    title={item.node.title}
                    fluid={
                      item.node.featured_media.localFile ? item.node.featured_media.localFile.childImageSharp.fluid : null
                    }
                    content={item.node.content}
                    format={item.node.format}
                    copy={item.node.excerpt}
                    tags={item.node.tags}
                    categories={item.node.categories}
                  />
                </div>
              )
            })
          }
        </Slider>
      </div>
    );
  }
}

export default Carousel;
